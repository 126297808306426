export const GAME_TIME_SECS = 2700;

export const ROUTES = {
	HOME: "/",
	REGISTER: "/register",
	VIDEO: "/video",
	CONTEXT: "/context",
	TRANSITION_SCENARIOS: "/scenarios",

	SCENARIO1_INTRO: "/scenario1-intro",
	SCENARIO1_INSTRUCTIONS: "/scenario1-instructions",
	SCENARIO1: "/scenario1",
	SCENARIO1_CONVERSATION_INTRO: "/scenario1-conversacion-intro",
	SCENARIO1_CONVERSATION: "/scenario1-conversacion",

	SCENARIO2_INTRO: "/scenario2-intro",
	SCENARIO2: "/scenario2",
	SCENARIO2_RESULT: "/scenario2-result",

	SCENARIO3_INTRO: "/scenario3-intro",
	SCENARIO3: "/scenario3",
	SCENARIO3_EXPLANATION: "/scenario3-explanation",
	SCENARIO3_RESULT: "/scenario3-result",

	SCENARIO4_INTRO: "/scenario4-intro",
	SCENARIO4: "/scenario4",

	SCENARIO5_INTRO: "/scenario5-intro",
	SCENARIO5_CONVERSATION: "/scenario5-conversation",
	SCENARIO5: "/scenario5",
	SCENARIO5_EXPLANATION: "/scenario5-explanation",
	SCENARIO5_RESULT: "/scenario5-result",
	SCENARIO5_END: "/scenario5-end",

	SCENARIO5_PART2_INTRO: "/scenario5.2-intro",
	SCENARIO5_PART2_INTRO2: "/scenario5.2-intro2",
	SCENARIO5_PART2: "/scenario5.2",
	SCENARIO5_PART2_END: "/scenario5.2-end",
	SCENARIO5_PART2_QUESTION2: "/scenario5.2-2",

	SCENARIO6_INTRO: "/scenario6-intro",
	SCENARIO6: "/scenario6",
	SCENARIO6_END: "/scenario6-end",

	SCENARIO7_INTRO: "/scenario7-intro",
	SCENARIO7: "/scenario7",
	SCENARIO7_QUESTION2: "/scenario7-2",
	SCENARIO7_QUESTION3: "/scenario7-3",
	SCENARIO7_END: "/scenario7-end",

	SUPERTEST_INTRO: "/supertest-intro",
	SUPERTEST_INSTRUCTIONS: "/supertest-instructions",
	SUPERTEST: "/supertest",
	SUPERTEST_END: "/supertest-end",

	FINAL_SCENE: "/final-scene",
	CONGRATULATIONS: "/congratulations",
	END: "/end",
	RESULTS: "/results",
	GAMEOVER: "/gameover",
};

export const SCENARIOS = {
	SCENARIO_1: "667ebae2d3dd862f3c03ebdc",
	SCENARIO_2: "667ebaf2d3dd862f3c03ebde",
	SCENARIO_3: "667ebafcd3dd862f3c03ebe0",
	SCENARIO_4: "667ebb06d3dd862f3c03ebe2",
	SCENARIO_5: "667ebb0fd3dd862f3c03ebe4",
	SCENARIO_6: "667ebb1fd3dd862f3c03ebe6",
	SCENARIO_7: "667ebb2ed3dd862f3c03ebe8",
	SUPERTEST: "667ebb39d3dd862f3c03ebea",
};

export const QUIZZES = {
	SCENARIO1: "667ebbb4d3dd862f3c03ebf3",
	SCENARIO2: "667ebbd8d3dd862f3c03ebf5",
	SCENARIO3: "667ebbf2d3dd862f3c03ebf7",
	SCENARIO5: "667ebc41d3dd862f3c03ebf9",
	SCENARIO5_2: "667ebc5ed3dd862f3c03ebfb",
	SCENARIO5_3: "667ebc6cd3dd862f3c03ebfd",
	SCENARIO6: "667ebca1d3dd862f3c03ebff",
	SCENARIO7: "667ebcc0d3dd862f3c03ec01",
	SCENARIO7_2: "667ebce7d3dd862f3c03ec03",
	SCENARIO7_3: "667ebce9d3dd862f3c03ec05",
	SUPERTEST_2: "667ebd12d3dd862f3c03ec09",
	SUPERTEST_3: "667ebd1dd3dd862f3c03ec0b",
};

export const QUESTION_ALREADY_ANSWERED_BUTTON = `<div class="d-flex align-items-center justify-content-center px-3">
		AVANZAR
	</div>`;

export const QUESTION_ALREADY_ANSWERED_TEXT = `<div>
		<div class="txt22">Ya has resuelto esta prueba anteriormente.</div>
		<div class="txt22 mt-2">¡Sigue adelante!</div>
	</div>`;

export const SCORE_TYPES = {
	SCORE: "SCORE",
	BONUS: "BONUS",
	PENALTY: "PENALTY",
};
